@import url('https://fonts.googleapis.com/css?family=Open+Sans');
* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

*:focus {
  outline: 0 !important;
}

.fixednav {
  position: fixed;
  z-index: 1000000;
  width: 100%;
}
.navbar {
  display: flex;
  flex-direction: row;
  text-decoration: none !important;
  list-style: none;
  justify-content: space-between;
  background-color: white !important;
  padding: 1rem 1rem;
  /* margin: 10px; */
}
.navmenu {
  margin: 9px 6px;
}
.navlist {
  font-weight: bold;
  color: #860b0b !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}

.navlist:hover {
  opacity: 0.5;
}

.mainlogo {
  width: 90%;
}

.heroabout {
  clear: both;
  /* background: url('../homepage/about5.jpg'); */
  height: 80vh !important;
  width: 100%;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.heroabout img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.heroinvest {
  /* background: url('../homepage/investmentcriteria5.jpg'); */
  height: 80vh;
  width: auto;
  text-align: center;
  object-fit: cover;
  /* background-size: contain; */
  /* object-fit: cover; */
  width: 100%;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.heroinvest img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contacthero {
  display: flex;
  /* background: url('../homepage/contact5.jpg'); */
  width: auto;
  text-align: center;
  height: 80vh !important;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin: auto auto; */
}

.contacthero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.crosshero {
  background: url('../homepage/team5.jpg');
  height: 80vh;
  /* width: auto; */
  text-align: center;
  object-fit: cover;
  background-position: 5% 20%;
  /* background-size: contain; */
  object-fit: fill;
  width: 100%;
  /* object-fit: fill; */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  /* top: 50px; */
}

.crosshero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teamhero {
  /* background: url('../homepage/crossborder5.jpg'); */
  height: 80vh;
  width: auto;
  text-align: center;
  width: 100%;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.teamhero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.herotxt {
  position: absolute;
  width: 60%;
  display: flex;
  justify-content: center;
  z-index: 10000;
  top: 40%;
  margin: 0 auto;
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.trans {
  border: none !important;
  background-color: #000;
}

button {
  border: none;
  background-color: rgb(255, 255, 255);
  outline: none;
}

.herobtn {
  display: flex;
  position: absolute;
  /* top: 68%; */
  /* left: 44%; */
  color: black;
  background-color: white;
  border: 1px solid white;
  padding: 16px 45px;
  border-radius: 80px;
  /* list-style: none; */
  text-decoration: none;
  align-items: center;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
}
.herobtn:hover {
  background-color: #860b0b;
  color: white;
  transition: 0.5s;
  cursor: pointer;
}

.hero-image {
  clear: both;
  /* background: url('../homepage/homepage-mobile5.jpg') !important; */
  height: 83vh !important;
  width: auto;
  text-align: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  object-fit: cover;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main {
  height: 60vh;
  background-color: #f8f9fa !important;
}
.main1 {
  height: 50vh;
}
.btn {
  background: url('../homepage/button.btn.primary-color.png');
  background-repeat: no-repeat;
  width: 20%;
  height: 100%;
  position: relative;
  top: 30%;
}
.mainarea {
  height: 150px;
  background-color: white;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 10%;
  top: 100px;
}
.aboutmain {
  text-align: center;
  margin: 100px 0;
}
.maincon {
  display: flex;
  flex-direction: row;
  /* margin: 0 2%; */
  min-width: 100%;
  margin-top: 30px;
}
.mainsec {
  background: url('../about/col-md-8.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5%;
  width: 45%;
  height: 80vh;
}
.sect1 {
  /* padding-right: 20px; */
  /* width: 50%; */
  text-align: left;
  /* padding: 10px; */
}

.sect2 {
  text-align: left;
  /* padding: 10px; */
}
.sectcomp {
  padding-bottom: 25px;
}
.points {
  display: flex;
  flex-direction: column;
  width: 52%;
  margin: 0 auto;
}
.list {
  clear: both;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-around;
}
.icon11 {
  margin-bottom: 25px;
}
.investment {
  background-color: rgb(241, 238, 238);
}
.investmain {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5% 0 0 0;
}
.investsection {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  width: 60%;
  /* height: 15vh; */
  margin-left: 20%;
  padding: 20px;
  border-bottom: 1px solid #dbdbdb;
}

.email-text {
  color: white;
}

.email-text:hover {
  text-decoration: none;
}

.heading {
  font-weight: bold;
  font-size: 17px;
  width: 40%;
}
.para {
  width: 100%;
  vertical-align: middle;
}

.download_icon {
  width: 15px;
  height: 15px;
}

.para ul {
  list-style: none;
  margin-bottom: 0 !important;
}
.investhr {
  width: 60%;
  height: 1px;
  background-color: rgba(109, 107, 107, 0.2);
}

.crossmain {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* margin: 5% 0 0 0; */
}
.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin: 5% 0; */
  text-align: start;
  /* margin-bottom: 55px; */
  padding-top: 50px;
}

.cards2 {
  padding-top: 0 !important;
}

.card {
  background-color: white;
  padding: 40px 20px;
  width: 25%;
}
.crosscards {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10% 0 0 0;
}
.cardcross {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -100px;
}
.cardborder {
  background-color: white;
  height: 150px;
  padding: 20px 20px;
  margin-bottom: 12%;
  width: 25%;
  /* margin-left: 80px; */
  margin-left: 40px;
  margin-right: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.cardborder:hover {
  background-color: #860b0b;
  color: white;
}

.cardteam {
  background-color: white;
  padding: 30px;
}

.section-description {
  width: 55%;
  text-align: justify;
  margin: 0 auto;
  padding: 20px !important;
  line-height: 25px;
  align-items: center;
}
.upper {
  background-color: #860b0b;
  color: white;
  text-align: center;
  padding: 40px 10px;
  margin: -30px -30px 1px -29px;
}
.card1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 50px;
  width: 350px;
  margin-left: 25px;
  margin-right: 25px;
}

.card1:hover {
  opacity: 0.8;
}

.teamcard {
  display: flex;
  flex-direction: row;
  margin: 5% 0;
  text-align: start;
}
.contactcards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 50px;
}
.contactcard1 {
  background-color: #252b42;
  color: white;
  padding: 100px 20px;
  width: 310px;
  /* margin-right: 30px; */
}
.contactcard2 {
  background-color: white;
  color: black;
  padding: 100px 20px;
  margin-left: 30px;
  width: 310px;
}
.btncon {
  border: 1px solid #d0a144;
  color: #d0a144;
  padding: 8px 40px;
  position: relative;
  bottom: -30px;
}

.btncon5 {
  border: 1px solid #d0a144;
  color: #d0a144;
  padding: 8px 40px;
  position: relative;
  bottom: -30px;
}

.footer {
  clear: both;
  height: 100%;
  padding: 25px;
  background-color: black;
}
.drpdown {
  width: 30px;
  height: 20px;
}

.navbar1 {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
}
.navlist1 {
  margin: 0 20px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.navlist1:hover {
  color: #860b0b;
  text-decoration: none;
}
hr {
  margin: 0px 10%;
  width: 80%;
  height: 5%;
  background-color: white;
  opacity: 0.7;
}
.rights {
  text-align: center;
  color: white;
  opacity: 0.7;
  padding-top: 25px;
}
.trans {
  width: 24px;
  height: 17px;
  margin-right: 10px;
}

.newyork {
  filter: brightness(80%);
}

.image_height {
  height: 90vh;
  width: 100%;
  object-fit: fill;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  /* opacity: 0.8; */
}

.boxtext {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dropdown-menu {
  width: 30px !important;
  min-width: 5rem;
}

.dropdown-item {
  /* width: 30px; */
  margin: 0 auto;
  padding: 5px;
}

.dropdown-item:focus {
  outline: none;
  text-decoration: none;
  border: none;
  color: white;
  outline: 0 !important;
}

.dropdown {
  display: flex;
}

p {
  margin-bottom: 0;
}

.icons {
  width: 50px;
  height: 50px;
}

.interests {
  margin: 0 auto;
}

.criteria {
  margin: 0 auto;
}

::marker {
  font-size: 0.8em;
}

.brochure-button {
  padding: 8px 16px;
  background-color: red;
}

.brochure-bottom {
  padding: 20px;
  margin: 50px;
  border: 1px solid grey;
  border-radius: 8px;
  background-color: #860b0b !important;
  color: white;
  /* margin-left: 45%; */
  /* width: 14%; */
}

.invest-button {
  display: flex;
  justify-content: center;
}

.navbar-brand {
  width: 35%;
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
}

.brochure-bottom:hover {
  opacity: 0.7;
}

.navbar-nav {
  padding: 0 !important;
}

.nav-item:hover,
.navlist:hover {
  background-color: #860b0b !important;
  color: white !important;
  opacity: 1;
  font: 25px;
}

.brochure-bottom {
  color: white !important;
  cursor: pointer;
}


.ml-4 {
  line-height: 22px;
}


@media screen and (max-width: 1199px) {
  .navbar1 {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
  }

  .navbar {
    display: flex;
    /* flex-direction: column; */
    text-decoration: none !important;
    list-style: none;
    justify-content: space-between;
    /* margin: 10px; */
  }

  .points {
    width: 58%;
  }

  .fixednav {
    height: 80px;
  }

  .navbar-collapse {
    text-align: center;
  }

  .navbar-brand {
    width: 70%;
  }

  .navbar-nav {
    padding: 0 !important;
  }

  .mainlogo {
    width: 80%;
  }
}
@media screen and (max-width: 990px) {
  .navbar-brand {
    width: 70%;
  }

  .navbar-nav {
    padding: 0 !important;
  }

  .herotxt {
    font-family: 'Martel', serif;
    position: absolute;
    display: flex;
    justify-content: center;
    z-index: 10000;
    top: 40%;
    width: 70%;
    /* left: 22%; */
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .mainarea {
    height: 350px;
    padding: 20px;
    background-color: white;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .sect1,
  .sect2 {
    /* width: 100%; */
    padding: 0;
    margin: 0;
  }

  .maincon {
    display: flex;
    flex-direction: column;
    /* margin: 0 2%; */
    width: 100%;
  }
  .points {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* .mainsec {
    background: url('../about/col-md-8.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 80vh;
  } */

  .mainlogo {
    width: 90%;
  }

  .card1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px auto !important;
    /* width: 55%; */
    padding-bottom: 20px;
  }

  .list {
    clear: both;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    align-items: center;
    padding-top: 30px;
  }

  .mainsec {
    display: none;
    visibility: hidden;
  }
  .crosscards {
    text-align: center;
  }
  .cardcross {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    /* margin-left: 100px; */
    text-align: start;
    margin: 0 auto;
  }

  .upper {
    padding: 40px;
  }

  .navbar-collapse {
    text-align: center;
  }

  .contactcards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
    padding: 30px;
  }

  .contactcard1,
  .contactcard2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .btn {
    background: url('../homepage/button.btn.primary-color.png');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
    top: 20%;
    left: 70px;
  }
  .investsection {
    clear: both;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* padding: 50px 0; */
    /* margin: 50px 0; */
    margin-top: 20px;
    width: 100% !important;
    height: 100%;
  }

  .heading {
    width: 92%;
    margin: 0 auto;
  }

  .para {
    width: 100%;
    padding: 20px;
    margin: 0 auto;
  }
  .cardborder {
    background-color: white;
    margin-bottom: 10%;
    width: 80%;
    /* height: 20%; */
    margin: 20px auto;
  }
  .cardcross {
    flex-wrap: wrap;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  /* .contactcard2 {
    margin: 0;
  } */

  .section-description {
    width: 100%;
    text-align: justify;
    margin: 0 auto;
    /* padding: 30px 10px; */
  }

  .investhr {
    display: none;
  }

  .navbar-light .navbar-toggler {
    /* display: flex;
    margin-top: -32px;
    margin-left: 900px; */
  }

  .heroabout {
    clear: both;
    /* background: url('../homepage/about5.jpg'); */
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .heroabout img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-image {
    clear: both;
    /* background: url('../homepage/homepage-mobile5.jpg') !important; */
    height: 90vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /* object-fit: cover; */
  }

  .hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .heroinvest {
    /* background: url('../homepage/investmentcriteria5.jpg'); */
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .heroinvest img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contacthero {
    /* background: url('../homepage/contact5.jpg'); */
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat !important;
  }

  .contacthero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .crosshero {
    /* background: url('../homepage/team5.jpg'); */
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .crosshero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .investsection {
    margin: 0 auto;
  }

  .teamhero {
    /* background: url('../homepage/crossborder5.jpg'); */
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
  }

  .teamhero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 800px) {
  .navbar {
    display: flex;
    flex-direction: row;
  }

  .list {
    clear: both;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    /* width: 100%; */
    /* align-items: center; */
  }

  .herotxt {
    width: 90%;
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .navbar-light .navbar-toggler {
    /* margin-top: -32px;
    margin-left: 700px; */
  }
}
@media screen and (max-width: 570px) {
  .mainlogo {
    width: 100%;
    /* margin-top: 10px; */
  }
  .navbar {
    display: flex;
    flex-direction: row;
    /* width: 100vw; */
  }
  .navbar-light .navbar-toggler {
    /* margin-top: -30px;
    margin-left: 420px; */
  }
  .w-100 {
    height: 350px;
  }
  .heroabout {
    clear: both;
    /* background: url('../homepage/about5.jpg'); */
    height: 50vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .heroabout img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 61% bottom;
  }

  .hero-image {
    clear: both;
    /* background: url('../homepage/homepage-mobile5.jpg') !important; */
    height: 65vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: 39% bottom;
  }

  .hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 39% bottom;
  }

  .image_height {
    clear: both;
    background: url('../homepage/homepage-mobile5.jpg') !important;
    height: 60vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .herobtn {
    padding: 15px 30px;
  }

  .heroinvest {
    /* background: url('../homepage/investmentcriteria5.jpg'); */
    height: 50vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .heroinvest img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .contacthero {
    /* background: url('../homepage/contact5.jpg'); */
    height: 50vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat !important;
    background-repeat: none;
  }

  .contacthero img {
    width: 100%;
    height: 100% !important;
    object-fit: cover;

    object-position: 33% bottom;
  }

  .crosshero {
    /* background: url('../homepage/team5.jpg'); */
    height: 50vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .crosshero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 12% bottom;
  }

  .investsection {
    margin: 0 auto;
  }

  .teamhero {
    /* background: url('../homepage/crossborder5.jpg'); */
    height: 50vh !important;
    width: auto;
    text-align: center;
    background-size: 100% 100%;
  }

  .teamhero img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 83% bottom;
  }

  /* .contactcard1 {
    width: 100%;
  } */
  .upper {
    padding: 40px 15px;
  }
  .cardcross {
    margin-top: 0px;
  }
  .crosscards {
    display: flex;
    flex-direction: column;
    text-align: center;
    /* margin: 15% 60px 0 0; */
  }
  .list {
    clear: both;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    /* align-items: center; */
  }
  .icon11 {
    width: 350px;
  }

  .investhr {
    display: none;
  }

  .navlist1 li {
    margin: 0 auto !important;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    font-weight: bold;
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .contactcards {
    display: flex;
    flex-direction: column;
  }

  .contactcard1,
  .contactcard2 {
    width: 100%;
    margin: 20px auto;
  }

  .sect1,
  .sect2 {
    padding-left: 0 !important;
  }

  .sect2 {
    margin-top: 0;
  }

  /* .icon11 {
    margin: 20px auto;
    width: 100%;
  } */

  .interests {
    padding: 20px;
    font-size: 28px;
  }
}
@media screen and (max-width: 380px) {
  .btn {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJgAAAA6CAYAAABMFpDEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAUxSURBVHgB7ZpNTBtHFMff2g2KhQXBIrIFAqkxqpoeIEjNBS4konDpoeWcL26l1ybXAuc05/RGSC69JLn0AkWJL3BpJT5UiarCFQotAhVBqYyIHJnt/N96XGMBpYFRvOj/k6z17s7OjpnfvvdmE0+KzI719nhRGRZfrpjdC0LI2+BJxi/sjXcOvngU7Brmx3uHfZERIeSU8CIy0nFzatSbHbt+x4tExoSQU8bfk2sRLxq5LYQ4ACVXxNRcPUKIC0w9HxFC3HGBghGnUDDiFApGnELBiFMoGHEKBSNOoWDEKRSMOIWCEadQMOIUCkacQsGIUygYcQoFI06hYMQpFIw4hYIRp7wn5FRo+nhIojVxWV94LPncurjifCItFz8ckEI+J6s/PZRqJxSCpfseSDzVXtov5Hdkd3NJ/lx8Jn+vzEg1UN/aLTXxpGxlJ5wKBokTbX16jzAIFqoUiT9qbn1Bn954qkPevzYqjZc/F1K9hCpFrs0/NhFiUr9fvDwgTVeHpPnql+bYDyodiJ6rlcaPBiSe7ND97ZXp0vlacyyRxtO/ZlLZEz3faPqJNaRld2tJNhaf67Fkxy2pqU1quouYiIGUhH4QPepbunSLfdv+MNCH9m/SGtg0Y7fjt2NtaOs3Y23XPg9rY38PHrDt36sjYh+X0NZgSI91Ld2aOhvSn+hkY0LT/Q80VSGNAkS6RLpfspN35fVWVtMLZNv45bluUx03dXIL+S7tA99xDKzM3JfaVEqvQQqM1tRKfmdd74N+teaaf3Lg+CrHgmuDsfTpWBTPK97LM4Jn9ZrW7nt6je23yTxAuD9APxhHmAj1KjK/s6ZbGyHwpGNycuvz8vN3n8nisxumVsvqeUgIoXJrCypGrOGSkSeQZPvVjG5x7fliX+ijHAiy/HJYFp/eMJE0mPz6lsMnW6Og6Q8RyY7FpnZELID9X7//Qs9nJ76SP358uK9fW28B226zLLqFgTPxmsJGq1hDm263X00Xj+dKosQqxIFcSI0AqRDUmfSHFAi2liYr7pEzaXFmXx82rR1ETTwVtF0L2iK9WTnqyqIQonBL1z2zkPmmFDltv1Z2XIsHBWwsPpUwEWrBbJ31uvjHt1jhgu+5fed2ihOOa/HBxOGDdpDQSloZwcr7PA4HyVeqE000BBCr2dSRiGpIkbsVv+MsEFrBbAoCVgYU6sBGK/1eFMa+OkBbTDQiDD6YWD1uUifaxhKXtO1JXzXYsdgxgnjyim53zL2ATX/Lma9l1aTHzd/2R803uaAEgJBW2HPFyBgWQlXkY+WIIjmWaCtFgZXp+yUZkNbQBp/ouXiw6mvt0uhTvjJDqkM/wKYciNfcOlQ6f1IwFtwjpSvSlI4FC5LgVUvwQEB0HE+239LXLxh3OVZ0SPrBp99q6meR7xBEJqSTwpuc1jPZibv7il5Eo+WXIzopwcqvS7+jOC+PSLYuCq4JIk15mt1ampCTAoms/HYsOr7Mv2NZtUW9kQYibpiVca4Y3Sx27JAsYV5poE1l2q9mvLnxXl/OIFjyA7xWeNccNRZNfyba4qE5qs5DH//Vpho5s/8WWQ1iWY4aC4Q5jjTV9Hv+D/zfFMQpFIw4hYIRp1Aw4hQKRpxCwYhTKBhxCgUjTqFgxCkUjDiFghGnUDDiFApGnELBiFMoGHEKBSNOoWDEKUYw/y8hxBER8bw5IcQFnmQifkFGhRAH+IW98Ujn4FTG36Nk5HTxRUY7B1888uyB2bHrdzwvctuEtR4h5K0w9bzvzfk+5JrK4Mg/ZDM+t7d9aEoAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;
    top: 10%;
    left: 70px;
  }
}










